/* src/components/Services.css */
.services {
    padding: 40px;
    background-color: var(--ColorPrincipalBack);
    text-align: center;
  }
  
  /* Grid para PC */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    padding: 0 20px;
  }
  
  /* Estilos para la tarjeta de servicio */
  .service-card {
    background-color: var(--FondoClaro);
    border-radius: 10px;
    box-shadow: 2px 4px 8px var(--ColorSombreBlanca);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .service-header {
    background-color: var(--FondoOscuro);
    padding: 15px;
    text-align: center;
  }
  
  .service-header h3 {
    font-family: var(--FuenteRubik);
    font-size: 2rem;
    color: var(--ColorResalte);
    margin: 0;
  }

  .service-header h4 {
    font-family: var(--FuenteRubik);
    font-size: 1.5rem;
    color: var(--FuenteSecundaria);
    margin: 20px 0 0 0;
  }
  
  .service-header h5{
    font-family: var(--FuenteSpaceGrotesk);
    font-size: 0.7rem;
    color: var(--FuentePrincipal);;
    margin: 0;
  }

  .service-header p{
    font-family: var(--FuenteSpaceGrotesk);
    font-size: 1.2rem;
    color: var(--FuentePrincipal);;
    margin: 0;
    padding: 5px 20px;
  }
  
  .service-body {
    padding: 20px;
    background-color: var(--FondoClaro);;
    text-align: left;
  }
  
  .service-feature {
    display: flex;
    align-items: center;
  }
  
  .service-feature p {
    margin-left: 10px;
    font-size: 1rem;
  }
  
  .icon {
    font-size: 1.5rem;
    color: var(--ColorSecundario);
  }
  
  .price-feature .icon {
    color: var(--ColorResalte);
  }
  
  .service-card .price {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 15px;
  }

  /* Grid para dispositivos móviles */
@media (max-width: 768px) {
  .services{
    padding: 50px 20px;
  }

  .services-grid {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
    gap: 50px; /* Ajuste del espacio entre tarjetas */
  }
}

/* src/components/Inicio.css */
.carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transform: translateX(100%) rotate(15deg); /* Elemento inicial fuera de la pantalla con rotación */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.carousel-item.active {
  opacity: 1;
  transform: translateX(0) rotate(0deg); /* El ítem activo vuelve al centro con rotación normal */
}

.carousel-item:not(.active) {
  transform: translateX(-100%) rotate(-15deg); /* Los ítems inactivos se van hacia la izquierda */
}

/* Centrar el contenido del carrusel y hacerlo ocupar el 90% */
.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: var(--FondoDegradado);
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 2s ease-in-out;

  /* Hacer que ocupe el 90% del ancho y alto de su contenedor */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* El contenido del carrusel aparecerá progresivamente */
.carousel-item.active .carousel-content {
  opacity: 1;
}

.carousel-content h2 {
  font-size: 7rem;
  margin: 0 2em 1em 2em;
  color: var(--FuenteSecundaria);
  font-family: var(--FuenteSpaceGrotesk), sans-serif;
}

.carousel-content p {
  font-size: 3rem;
  margin: 0 3em 1em 3em;
  color: var(--FuentePrimaria);
  font-family: var(--FuenteRubik), sans-serif;
}

/* Indicadores del carrusel (círculos verticales) */
.carousel-indicators {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid var(--FuentePrincipal);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: var(--FuentePrincipal);
}

@media (max-width: 768px) {
  .carousel-content h2 {
    font-size: 3.5rem; /* Ajustar tamaño del h2 en móvil */
  }

  .carousel-content p {
    font-size: 2rem; /* Ajustar tamaño del p en móvil */
  }
}